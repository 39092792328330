<template>
  <div class="app-container">
    <div class="banner"></div>
    <div class="info-wrapper flex">
      <div class="info-left"></div>
      <div class="info-right">
        <div class="right-top">
          <div class="title">CONTACT US</div>
          <div class="contact-info">
            <div class="info-item">
              <div class="item-title">电话</div>
              <div class="item-context">029-85796557</div>
            </div>
            <div class="info-item">
              <div class="item-title">邮箱</div>
              <div class="item-context">business@natuxtech.com</div>
            </div>
            <div class="info-item">
              <div class="item-title">地址</div>
              <div class="item-context">
                西安市雁塔区雁翔路交大科技园C座1301室
              </div>
            </div>
          </div>
        </div>
        <div class="right-bot flex flex-column flex-1">
          <div class="title">DROUP US A LINE</div>

          <div class="form-area flex-1">
            <div class="input-area">
              <div class="first-row">
                <el-input v-model="form.name" class="left">
                  <template slot="prepend">Name *</template>
                </el-input>
                <el-input v-model="form.tel" class="right">
                  <template slot="prepend">Phone *</template>
                </el-input>
              </div>

              <el-input v-model="form.email" class="email">
                <template slot="prepend">Email *</template>
              </el-input>

              <div class="message-title">Message</div>
              <el-input v-model="form.message" type="textarea" resize="none">
              </el-input>
            </div>

            <el-button class="sendBtn" type="warning" @click="send">立即发送</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        tel: "",
        email: "",
        message: "",
      },
    };
  },

  methods: {
      send(){
          this.$confirm("功能维护中！","提示",{type: "info"})
      }
  }
};
</script>

<style lang="scss">
@function vw($px) {
  @return ($px / 1920) * 100vw;
}
.form-area {
  .input-area {
    .el-input-group__prepend {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #adadad;
      border-radius: 0;
      padding: 0;
      padding-right: vw(10);
    }
    .el-input__inner {
      border: none;
      border-bottom: 1px solid #adadad;
      border-radius: 0;
      padding: 0;
    }
    .el-textarea__inner {
      border: none;
      border-bottom: 1px solid #adadad;
      border-radius: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
@function vw($px) {
  @return ($px / 1920) * 100vw;
}

.banner {
  height: vw(516);
  width: 100%;
  background: url("~@/assets/contactUs/banner.png") no-repeat center;
  background-size: cover;
}

.info-wrapper {
  padding: vw(79) vw(220) vw(120) vw(223);
  .info-left {
    width: vw(859);
    height: vw(630);
    margin-right: vw(75);
    background: url("~@/assets/contactUs/map.png") no-repeat center;
    background-size: cover;
  }
  .info-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .title {
      font-size: vw(30);
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      text-align: start;
    }
    .right-top {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .contact-info {
        width: 100%;
        margin-top: vw(46);
        margin-bottom: vw(72);
        display: flex;
        flex-wrap: wrap;
        .info-item {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-bottom: vw(39);
          .item-title {
            font-size: vw(16);
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            margin-bottom: vw(12);
            text-align: start;
          }

          .item-context {
            font-size: vw(14);
            text-align: start;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .right-bot {
      .form-area {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: vw(32);
        .input-area {
          display: flex;
          flex-direction: column;
          .first-row {
            display: flex;
            margin-bottom: vw(25);
            .left {
              margin-right: vw(50);
            }
          }
          .email {
            margin-bottom: vw(25);
          }
          .message-title {
            font-size: vw(14);
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #909399;
            text-align: start;
          }
        }

        .sendBtn {
          width: vw(150);
          height: vw(32);
          padding: 0;
        }
      }
    }
  }
}
</style>